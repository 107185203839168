import React from 'react'

const bannerComponent = () => {
  return (
    <section className="pt-7">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-md-start text-center py-6">
            <h1 className="mb-4 fs-9 fw-bold">Bingung Buat Aplikasi? Asarana Solusinya!</h1>
            <p className="mb-6 lead text-secondary">Asarana merupakan startup yang bergerak dalam sektor jasa<br className="d-none d-xl-block" />pembuatan Website Apps, Mobile Apps, Desktop Apps dan seputar IT lainnya.</p>
            <div className="text-center text-md-start">
              <a className="btn btn-warning me-3 btn-lg" href="#services" role="button">Selengkapnya</a>
              {/* <a className="btn btn-link text-warning fw-medium" href="#!" role="button"><span className="fas fa-play me-2"></span>Watch the video </a> */}
            </div>
          </div>
          <div className="col-md-6 text-end"><img className="pt-5 pt-md-0 img-fluid w-80" src="assets/svg/undraw/63bb324464396f65d699d1374e55fd32.svg" alt="" /></div>
        </div>
      </div>
    </section>
  )
}

export default bannerComponent