import React from 'react'

const portofolioContentLanding = () => {
  return (
    <section className="pt-3" id="portofolio">
      <div className="container">
        <h1 className="fw-bold fs-6 mb-3">Portofolio Asarana</h1>
        <p className="mb-6 text-secondary">Portofolio yang telah kami kerjakan, yuk dicek!</p>
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="card">
              <a href="https://melijo.id/" rel="noreferrer" target="_blank"><img className="card-img-top" src="assets/img/portofolios/melijo.png" alt="" /></a>
              <div className="card-body ps-0">
                <p className="text-secondary">By <a className="fw-bold text-decoration-none me-1" href={'/'}>Asarana Team</a>|<span className="ms-1">03 October 2022</span></p>
                <h3 className="fw-bold">Aplikasi Melijo.id</h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <a href="https://qema-farm.web.app/" rel="noreferrer" target="_blank"><img className="card-img-top" src="assets/img/portofolios/qemafarm.png" alt="" /></a>
              <div className="card-body ps-0">
                <p className="text-secondary">By <a className="fw-bold text-decoration-none me-1" href={'/'}>Asarana Team</a>|<span className="ms-1">28 March 2023</span></p>
                <h3 className="fw-bold">QemaFarm Application</h3>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <div className="card">
              <a href="https://booqu.id/" rel="noreferrer" target="_blank"><img className="card-img-top" src="assets/img/portofolios/booqu.jpeg" alt="" /></a>
              <div className="card-body ps-0">
                <p className="text-secondary">By <a className="fw-bold text-decoration-none me-1" href={'/'}>Asarana Team</a>|<span className="ms-1">01 May 2023</span></p>
                <h3 className="fw-bold">Booqu.id Application</h3>
              </div>
            </div>
          </div>
        </div>
        {/* <a href="#!" className="float-right">Selengkapnya</a> */}
      </div>

    </section>
  )
}

export default portofolioContentLanding