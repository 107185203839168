import React from 'react'

const serviceContentLanding = () => {
  return (
    <section className="pt-5 pt-md-9 mb-6" id="services">
      <div className="bg-holder z-index--1 bottom-0 d-none d-lg-block holder-bottom">
      </div>
      <div className="container">
        <h1 className="fs-9 fw-bold mb-4 text-center"> Apa aja sih layanan <br className="d-none d-xl-block" /> Asarana?</h1>
        <div className="row mb-5">
          <div className="col-lg-3 col-sm-6 mb-2"> 
            <a href="#software-dev" class="badge badge-pill rounded-pill bg-primary py-3 px-3 mb-5 cursor-pointer"> WEB </a>
            <h4 className="mb-3">Website Development</h4>
            <p className="mb-0 fw-medium text-secondary">Pembangunan website company/personal profile untuk UMKM, startup dan perusahaan.</p>
          </div>
          <div className="col-lg-3 col-sm-6 mb-2"> 
          <a href="#mobile-dev" class="badge badge-pill rounded-pill bg-warning py-3 px-3 mb-5 cursor-pointer"> APP </a>
            <h4 className="mb-3">Mobile Development</h4>
            <p className="mb-0 fw-medium text-secondary">Pembuatan aplikasi untuk Sistem Operasi Android, IOS dengan menggunakan teknologi terkini.</p>
          </div>
          <div className="col-lg-3 col-sm-6 mb-2"> 
          <a href="#design-dev" class="badge badge-pill rounded-pill bg-twitter py-3 px-3 mb-5 cursor-pointer"> UIX </a>
            <h4 className="mb-3">UI/UX Design</h4>
            <p className="mb-0 fw-medium text-secondary">UI/UX design untuk membuat tampilan desain website, desktop & mobile app mudah digunakan.</p>
          </div>
          <div className="col-lg-3 col-sm-6 mb-2"> 
          <a href="#software-dev" class="badge badge-pill rounded-pill bg-github py-3 px-3 mb-5 cursor-pointer"> DEV </a>
            <h4 className="mb-3">Software Development</h4>
            <p className="mb-0 fw-medium text-secondary">Pembuatan aplikasi untuk Sistem Operasi Windows dengan menggunakan teknologi terkini.</p>
          </div>
        </div>
        {/* <div className="text-center"><a className="btn btn-warning" href="#!" role="button">SIGN UP NOW</a></div> */}
      </div>

    </section>
  )
}

export default serviceContentLanding